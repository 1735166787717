function checkoutPage() {
    'use strict';

    function submitAddressEditForm(form: JQuery<any>) {
        var href = form.data('nextstep');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success && data.data == 'VAT_CHANGED') {
                    href = '//' + location.host + location.pathname + '?warning=msg.order.vat.changed';
                }
                window.location.href = href;
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }

    $('#dispatch-address-form').validate({
        errorPlacement: customErrorPlacement,
        submitHandler: function(form, event) {
          event.preventDefault();
          submitAddressEditForm($(form));
        }
    });
        
    $('#billing-address-form').validate({
        submitHandler: function(form, event) {
          event.preventDefault();
          if(!$('#clausola1').is(':checked')) {
            showMessageBox('danger', msg['ERR_PRIVACY']);
            $('#clausola1').addClass('error')
          }
          else {
            submitAddressEditForm($(form));
          }
        }
    });

    $('#copy-dispatch').on('click', function() {
        copyFormInputs('#billing-address-form', '#billing-address-form', {
            'addressee_disp': 'addressee',
            'cap_disp': 'cap',
            'city_disp': 'city',
            'number_disp': 'number',
            'prov_disp': 'prov',
            'street_disp': 'street',
            'country_disp': 'country_id',
        });
    });

    monitorInput('#billing-address-form', 'country_id',
        function(input) {
            const eu = $(input).find('option:selected').data('eu');
            return !!eu && eu != 'EXTRA-EU';
        },
        function() {
            const input = $('#billing-address-form').find('[name=cfpiva]');
            input.required(true);
            $('#cfpiva-div').show();
        },
        function() {
            const input = $('#billing-address-form').find('[name=cfpiva]');
            input.required(false);
            $('#cfpiva-div').hide();
        }
    );

    monitorInput('#billing-address-form', 'country_id',
        function(input) {
            const eu = $(input).find('option:selected').data('eu');
            return !!eu && eu == 'IT';
        },
        function() {
            const sdi = $('#billing-address-form').find('[name=sdi]');
            const pec = $('#billing-address-form').find('[name=pec]');
            pec.disabled(false);
            sdi.disabled(false);
            $('#pec-div').show();
            $('#sdi-div').show();
        },
        function() {
            const sdi = $('#billing-address-form').find('[name=sdi]');
            const pec = $('#billing-address-form').find('[name=pec]');
            pec.disabled(true);
            sdi.disabled(true);
            $('#pec-div').hide();
            $('#sdi-div').hide();
        }
    );
    
    $('[name*="country"]').not('[type=hidden]').on('change', updateProvinces);
    updateProvinces();
}
function menuComponent() {
    $('.menu').on('toggle:was-opened', function(event, selector) {
        const menuItem = $(selector).closest('.menu-item');
        $(this).find('.menu-item.selected').withData('level', menuItem.data('level').toString()).removeClass('selected');
    });

    $('.menu-item').withData('level', '1').children('a, span').on('click', function(event) {
        const thisItem = $(this).parent();
        const level = thisItem.data('level');

        responsive({
            tablet: function() {
                event.preventDefault();
                const otherItems = $('.menu-item').withData('level', level).not(thisItem);
                otherItems.removeClass('open').trigger('menu:change');
                thisItem.toggleClass('open').trigger('menu:change');
            }
        });
    });

    $('.menu-item').withData('level', '1').on('menu:change', function(event) {
        event.stopPropagation();
        const thisItem = $(this);
        const otherItems = $('.menu-item').withData('level', '1').not(thisItem);


        responsive({
            tablet: function() {
                if (thisItem.is('.open')) {
                    thisItem.children('.submenu').slideDown();
                    otherItems.slideUp();
                }
            }
        });
    });

    $('.menu-item').withData('level', '2').on('menu:change', function() {
        const thisItem = $(this);

        
        responsive({
            tablet: function() {
                if (thisItem.is('.open')) {
                    thisItem.children('.submenu').slideDown();
                }
                else {
                    thisItem.children('.submenu').slideUp();
                }
            }
        });
    });

    $('.menu-item').withData('level').on('click', function(event) {
        event.stopPropagation();
    });
 
    $('.menu-item.logout').on('click', function() {
        $<HTMLFormElement>('#logout-form').get(0).submit();
    })

    $('.has-submenu').on('mouseover', function() {
        if(window.innerWidth > 1024){
            $(this)
                .siblings('.has-submenu.open')
                    .removeClass('open selected')
                    .find('.submenu')
                        .removeClass('open selected');
            $(this).addClass('open');
            $(this).find('.submenu')
                .addClass('open');
        }
    });

    $('.menu-item[data-level="1"]:not(.has-submenu)').on('mouseover', function() {
        if(window.innerWidth > 1024){
            $(this)
                .siblings('.has-submenu.open')
                    .removeClass('open selected')
                    .find('.submenu')
                        .removeClass('open selected');
        }
    });
}
function cmsPages() {
    playVideo();
}

function playVideo() {
    $('.video-container .video-cover').on('click touchend', function () {
        const container = $(this).closest('.video-container');
        const cover = $(this);
        const box = container.find('.video-box');
        const video = box.find('.video');
        video.attr('src', video.data('src'));
        cover.hide();
        box.show();
    });
}
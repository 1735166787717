function contactComponent() {
    if (exists('.g-recaptcha')) {
        loadCaptcha();
    }

    monitorInput('#contact-form', 'subject',
    function(input) {
        return $(input).val() != '';
    },
    function() {
        const val = $('#contact-form').find('[name=subject]').val();
        $('#contact-form').find('.contact-form-options').hide();
        $('#' + val).show();
    },
    function() {
        $('#contact-form').find('.contact-form-options').hide();
    }
);
}

function submitContactForm() {
    const $form = $('#contact-form');

    if ($form.valid()) {
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function (data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                }
                else {
                    showMessageBox('danger', data.errors.message);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function () {
                grecaptcha.reset();
                expiredContactFormCaptcha();
            }
        });
    }
}

function contactFormCaptcha() {
    $('#contact-form [type="submit"]').prop('disabled', false);
}

function expiredContactFormCaptcha() {
    $('#contact-form [type="submit"]').prop('disabled', true);
}
var skin = "/skins/eltra";
var messageBoxDuration = 1500

$(function () {
    if (exists("#home-container")) {
        homePage();
    }
    else if (exists("#cart-container")) {
        cartPage();
    }
    else if (exists("#catalog-container")) {
        catalogPage();
    }
    else if (exists("#checkout-container")) {
        checkoutPage();
    }
    else if (exists("#product-container")) {
        productPage();
    }
    else if (exists("#profile-container")) {
        profilePage();
    }
    if (exists(".cms-wrapper")) {
        cmsPages();
    }

    if (exists(".subscribe-container")) {
        subscribeComponent();
    }
    if (exists(".product-container")) {
        productComponent();
    }
    if (exists("header")) {
        headerComponent();
    }
    if (exists('#search-modal')) {
        searchComponent();
    }
    if (exists("#main-menu, #mobile-menu")) {
        menuComponent();
    }
    if (exists("#contact-form")) {
        contactComponent();
    }
    if (exists(".swiper-container")) {
        swiperComponent();
    }
    if (exists('#menu-full')) {
        menuComponent();
    }
    if (exists('#news-popup')) {
        newsPopup();
    }

});


// Other scripts to be called on every page
$(function () {
    initInputMasks();
    initForceUpperCase();
    initToggle();
    initDialogs();
    initTabs();
    initSortableTables();
    initSearch();
    initEventEmitters();
    initMultiStep();
    initTelDropdowns();
    initBackgroundImageLazyLoad();

    if ($('.alert-box').is(':visible')) {
        blockPageScroll();
        setTimeout(function() {
            $('.alert-box').hide();
            restorePageScroll();
        }, messageBoxDuration);
    }

    $('a[href*="#"]').on('click', function (event) {
        event.preventDefault();

        const hash = $(this).attr('href');
        scrollToElement(hash).then(() => window.location.hash = hash);
    });

    $(document)
        .on('set-currency', function(event, currency) {
            event.preventDefault();
            const currentCurrency = checkCookie('currency');

            if (currency != currentCurrency) {
                createCookie('currency', currency);
                window.location.reload();
            }
        })
        .on('toggle-page-scroll', function() {
            if (pageIsScrollable()) {
                blockPageScroll();
            }
            else {
                restorePageScroll();
            }
        });
});
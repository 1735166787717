function newsPopup() {
    setTimeout(() => {
        blockPageScroll();

        $<HTMLDialogElement>('#news-popup')
            .on('close', function() {
                createCookie('newsPopupCookie', 'set');
            })
            //@ts-ignore
            .get(0).showModal();
    
        $('#news-popup a').on('click', function(event) {
            event.preventDefault; 
            createCookie('newsPopupCookie', 'set');
            window.location.href=$(this).attr('href')
        });
    }, 3000);
}